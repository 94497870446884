import React, { useEffect, useRef, useState } from 'react'
// import PropTypes from 'prop-types'
import { Box, Button, Container, Divider, FormControl, Grid, InputLabel, LinearProgress, makeStyles, MenuItem, Paper, Select, TextField, Typography, Tooltip, InputAdornment } from '@material-ui/core';
import { api, apiPartial } from '../../api/api';
import { getWarehouseCustomer, listCustomerTypes, listOrganizations, listWarehouses, listRemoteCommands, listRemoteControls } from '../../graphql/queries';
import { AddCircle, Send } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { listCustomersAudit } from '../../graphql/bamaQueries';
import { Autocomplete } from '@material-ui/lab';
import { SNACKBAR_ERROR, SNACKBAR_INFO, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from '../../utils/SessionControl';
import ShowListKits from './ShowListKits';
import { withSnackbar } from 'notistack';
import { createRemoteCommand, createRemoteControl, updateRemoteCommand } from '../../graphql/mutations';
import moment from 'moment';
import AddCommand from './AddCommand';
import { IconButton } from '@material-ui/core';

const styles = makeStyles(theme => ({
    formControlHalf: {
        margin: theme.spacing(2),
        minWidth: '46%'
    },
    formControl3: {
        margin: theme.spacing(1),
        minWidth: '40%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(2)
    }
}))

const listCustomersTypes = {
    "Clientes Bama Agencia Gris": {
        "id": "123eb5fd-33f5-4be8-b522-947cee3751a5",
        "createdAt": "2024-09-13T16:18:26.723Z",
        "customerTypeId": "BAMA BA GRIS",
        "customerTypeName": "Clientes Bama Agencia Gris",
        "updatedAt": "2024-10-02T07:07:23.683Z",
        "_lastChangedAt": 1727852843683,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Agencia Herminio": {
        "id": "52414926-fdf3-4e5b-a73a-9206680a5056",
        "createdAt": "2024-09-13T16:18:26.758Z",
        "customerTypeId": "BAMA BA HERMINIO",
        "customerTypeName": "Clientes Bama Agencia Herminio",
        "updatedAt": "2024-10-02T07:07:23.701Z",
        "_lastChangedAt": 1727852843701,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Agencia Luis": {
        "id": "63aee504-5844-42ac-9e06-fd73709b8ff2",
        "createdAt": "2024-09-13T16:18:26.690Z",
        "customerTypeId": "BAMA BA LUIS",
        "customerTypeName": "Clientes Bama Agencia Luis",
        "updatedAt": "2024-10-02T07:07:23.717Z",
        "_lastChangedAt": 1727852843717,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Agencia Nacho": {
        "id": "83304c39-61d8-4790-88fd-25615bdeb39f",
        "createdAt": "2024-09-13T16:18:26.774Z",
        "customerTypeId": "BAMA BA NACHO",
        "customerTypeName": "Clientes Bama Agencia Nacho",
        "updatedAt": "2024-10-02T07:07:23.734Z",
        "_lastChangedAt": 1727852843735,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Agencia Omar": {
        "id": "62c65486-d42b-4cd8-9434-d6d465e96e05",
        "createdAt": "2024-09-13T16:18:26.675Z",
        "customerTypeId": "BAMA BA OMAR",
        "customerTypeName": "Clientes Bama Agencia Omar",
        "updatedAt": "2024-10-02T07:07:23.750Z",
        "_lastChangedAt": 1727852843751,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Agencia Torton": {
        "id": "038f9681-2990-4915-87cd-eaee0206b24f",
        "createdAt": "2024-09-13T16:18:26.739Z",
        "customerTypeId": "BAMA BA TORTON",
        "customerTypeName": "Clientes Bama Agencia Torton",
        "updatedAt": "2024-10-02T07:07:23.767Z",
        "_lastChangedAt": 1727852843768,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes bama club": {
        "id": "5136d4f7-7a43-4a67-a731-499309c13b1e",
        "createdAt": "2024-07-20T00:04:04.368Z",
        "customerTypeId": "BAMA BC",
        "customerTypeName": "Clientes bama club",
        "defaultType": true,
        "updatedAt": "2024-08-09T23:18:09.310Z",
        "_lastChangedAt": 1723245489310,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Especiales Merida": {
        "id": "14f6105a-743b-4ee9-987f-e97e360c3d21",
        "createdAt": "2024-09-13T16:18:26.659Z",
        "customerTypeId": "BAMA BC ESP MER",
        "customerTypeName": "Clientes Bama Club Especiales Merida",
        "updatedAt": "2024-10-02T07:07:23.802Z",
        "_lastChangedAt": 1727852843802,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Especiales Cdmx": {
        "id": "a042257c-2079-4402-9deb-99594d8122ac",
        "createdAt": "2024-09-13T16:18:26.578Z",
        "customerTypeId": "BAMA BC ESP MEX",
        "customerTypeName": "Clientes Bama Club Especiales Cdmx",
        "updatedAt": "2024-10-02T07:07:23.818Z",
        "_lastChangedAt": 1727852843819,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Especiales": {
        "id": "afd5d31b-eba1-45d6-8819-a8b33e1218ef",
        "createdAt": "2024-09-13T16:18:26.626Z",
        "customerTypeId": "BAMA BC ESPECIALES",
        "customerTypeName": "Clientes Bama Club Especiales",
        "updatedAt": "2024-10-02T07:07:23.834Z",
        "_lastChangedAt": 1727852843835,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Merida": {
        "id": "52408215-e76a-4a31-9523-f89ecfdca0a6",
        "createdAt": "2024-09-13T16:18:26.594Z",
        "customerTypeId": "BAMA BC MER",
        "customerTypeName": "Clientes Bama Club Merida",
        "updatedAt": "2024-10-02T07:07:23.850Z",
        "_lastChangedAt": 1727852843850,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Cdmx": {
        "id": "12b14a8a-6a15-4de3-a0f9-5111b5ea0913",
        "createdAt": "2024-09-13T16:18:26.526Z",
        "customerTypeId": "BAMA BC MEX",
        "customerTypeName": "Clientes Bama Club Cdmx",
        "updatedAt": "2024-10-02T07:07:23.867Z",
        "_lastChangedAt": 1727852843868,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Oro": {
        "id": "9ca4a324-1ef8-4471-9e87-276c1c49df76",
        "createdAt": "2024-09-13T16:18:26.498Z",
        "customerTypeId": "BAMA BC ORO",
        "customerTypeName": "Clientes Bama Club Oro",
        "updatedAt": "2024-10-02T07:07:23.888Z",
        "_lastChangedAt": 1727852843889,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Oro Merida": {
        "id": "dcf7e2a5-6c82-43ed-ac66-5aa1b793104a",
        "createdAt": "2024-09-13T16:18:26.643Z",
        "customerTypeId": "BAMA BC ORO MER",
        "customerTypeName": "Clientes Bama Club Oro Merida",
        "updatedAt": "2024-10-02T07:07:23.904Z",
        "_lastChangedAt": 1727852843905,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Oro Tco": {
        "id": "cb065945-a203-4666-bada-3ec346d4a926",
        "createdAt": "2024-08-10T08:10:38.228Z",
        "customerTypeId": "TCO BC ORO",
        "customerTypeName": "Clientes Bama Club Oro Tco",
        "updatedAt": "2024-10-05T18:03:15.423Z",
        "_lastChangedAt": 1728151395423,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Oro Cdmx": {
        "id": "93d087ee-568d-4aa6-bffd-51e48c62f9b6",
        "createdAt": "2024-09-13T16:18:26.561Z",
        "customerTypeId": "BAMA BC ORO MEX",
        "customerTypeName": "Clientes Bama Club Oro Cdmx",
        "updatedAt": "2024-10-02T07:07:23.920Z",
        "_lastChangedAt": 1727852843920,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Plata Merida": {
        "id": "392107bb-ea9a-4478-9bbb-805c686425b1",
        "createdAt": "2024-09-13T16:18:26.608Z",
        "customerTypeId": "BAMA BC PLA MER",
        "customerTypeName": "Clientes Bama Club Plata Merida",
        "updatedAt": "2024-10-02T07:07:23.936Z",
        "_lastChangedAt": 1727852843936,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Plata Cdmx": {
        "id": "b6a6ddfc-d4e3-4366-a6a3-6504b47105df",
        "createdAt": "2024-09-13T16:18:26.543Z",
        "customerTypeId": "BAMA BC PLA MEX",
        "customerTypeName": "Clientes Bama Club Plata Cdmx",
        "updatedAt": "2024-10-02T07:07:23.952Z",
        "_lastChangedAt": 1727852843952,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Plata": {
        "id": "4f8cef06-1063-4125-ade8-5744b8597a81",
        "createdAt": "2024-09-13T16:18:26.479Z",
        "customerTypeId": "BAMA BC PLATA",
        "customerTypeName": "Clientes Bama Club Plata",
        "updatedAt": "2024-10-02T07:07:23.967Z",
        "_lastChangedAt": 1727852843968,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Tco": {
        "id": "53786c5e-144b-46dd-a142-99bc1e94ef33",
        "createdAt": "2024-08-10T08:10:38.198Z",
        "customerTypeId": "TCO BC",
        "customerTypeName": "Clientes Bama Club Tco",
        "updatedAt": "2024-09-28T07:07:01.197Z",
        "_lastChangedAt": 1727507221198,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes club conviene": {
        "id": "4d8e7b38-de1d-47cf-9731-50fb249e0d87",
        "createdAt": "2020-07-21T09:38:35.670Z",
        "customerTypeId": "CC001",
        "customerTypeName": "Clientes club conviene",
        "defaultType": true,
        "updatedAt": "2023-09-07T17:31:44.590Z",
        "_lastChangedAt": 1694107904592,
        "_version": 3,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Coatza": {
        "id": "a8961a63-b568-44f2-bd67-d6ea7ae3ae50",
        "createdAt": "2024-08-02T21:44:24.652Z",
        "customerTypeId": "BAMA CC",
        "customerTypeName": "Clientes Conviene Coatza",
        "updatedAt": "2024-10-02T07:07:23.982Z",
        "_lastChangedAt": 1727852843983,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Especial Nanchital": {
        "id": "a644bc72-c7e9-4ea2-841d-0bbaa0efeba7",
        "createdAt": "2024-09-18T15:17:26.179Z",
        "customerTypeId": "BAMA CC ESP NANCHITAL",
        "customerTypeName": "Clientes Conviene Especial Nanchital",
        "updatedAt": "2024-10-02T07:07:23.998Z",
        "_lastChangedAt": 1727852843999,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Especial Sur": {
        "id": "897838b4-c1ec-43ff-93e0-fa961651743e",
        "createdAt": "2024-09-18T15:17:26.164Z",
        "customerTypeId": "BAMA CC ESP SUR",
        "customerTypeName": "Clientes Conviene Especial Sur",
        "updatedAt": "2024-10-02T07:07:24.013Z",
        "_lastChangedAt": 1727852844014,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Mayoristas": {
        "id": "411257c2-fc2b-4d15-a7a4-1ce6bdec89e4",
        "createdAt": "2024-09-18T15:17:26.151Z",
        "customerTypeId": "BAMA CC MAYORISTAS",
        "customerTypeName": "Clientes Conviene Mayoristas",
        "updatedAt": "2024-10-02T07:07:24.030Z",
        "_lastChangedAt": 1727852844030,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Nanchital": {
        "id": "8b665a81-53ed-4f74-a5c6-95f861457866",
        "createdAt": "2024-09-18T15:17:26.137Z",
        "customerTypeId": "BAMA CC NANCHITAL",
        "customerTypeName": "Clientes Conviene Nanchital",
        "updatedAt": "2024-10-02T07:07:24.047Z",
        "_lastChangedAt": 1727852844048,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Norte": {
        "id": "c7bffb8d-cd4c-46e7-9bd5-4320644c64b2",
        "createdAt": "2024-09-18T15:17:26.107Z",
        "customerTypeId": "BAMA CC NORTE",
        "customerTypeName": "Clientes Conviene Norte",
        "updatedAt": "2024-10-02T07:07:24.062Z",
        "_lastChangedAt": 1727852844063,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Conviene Sur": {
        "id": "d0e18de8-f6e9-49a4-96cf-acc6ee4094b6",
        "createdAt": "2024-09-18T15:17:26.122Z",
        "customerTypeId": "BAMA CC SUR",
        "customerTypeName": "Clientes Conviene Sur",
        "updatedAt": "2024-10-02T07:07:24.078Z",
        "_lastChangedAt": 1727852844078,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Uxpanapa": {
        "id": "bd31e7e7-6a24-4fbc-ab0d-458c69de85c9",
        "createdAt": "2024-10-01T07:07:01.017Z",
        "customerTypeId": "BAMA BC UXP",
        "customerTypeName": "Clientes Bama Club Uxpanapa",
        "updatedAt": "2024-10-01T07:07:01.017Z",
        "_lastChangedAt": 1727766421018,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Plata Uxpanapa": {
        "id": "bd31e7e7-6a24-4fbc-ab0d-458c69de85c9",
        "createdAt": "2024-10-01T07:07:01.017Z",
        "customerTypeId": "BAMA BC UXP",
        "customerTypeName": "Clientes Bama Club Uxpanapa",
        "updatedAt": "2024-10-01T07:07:01.017Z",
        "_lastChangedAt": 1727766421018,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club Oro Uxpanapa": {
        "id": "2b005d04-df26-4ef9-9b6b-dd8cf65bc550",
        "createdAt": "2024-10-01T07:07:01.038Z",
        "customerTypeId": "BAMA BC PLA UXP",
        "customerTypeName": "Clientes Bama Club Plata Uxpanapa",
        "updatedAt": "2024-10-01T07:07:01.038Z",
        "_lastChangedAt": 1727766421039,
        "_version": 1,
        "__typename": "CustomerType"
    },
    "Clientes Bama Club": {
        "id": "9669b5b5-aced-47e0-9eb2-d479c012dffc",
        "createdAt": "2024-10-01T07:07:01.053Z",
        "customerTypeId": "BAMA BC ORO UXP",
        "customerTypeName": "Clientes Bama Club Oro Uxpanapa",
        "updatedAt": "2024-10-01T07:07:01.053Z",
        "_lastChangedAt": 1727766421054,
        "_version": 1,
        "__typename": "CustomerType"
    }
}

const Commands = (props) => {
    const firstrender = useRef(true);
    const classes = styles();

    /* handlers */
    const [orgLoader, setOrgLoader] = useState(false);
    const [sucLoader, setSucLoader] = useState(false);
    const [ctesLoader, setCtesLoader] = useState(false);
    const [addCommLoader, setAddCommLoader] = useState(false);
    const [tblLoaderData, setTblLoaderData] = useState(false);
    const [processComplete, setProcessComplete] = useState(false);

    /* data */
    const [empresas, setEmpresas] = useState([]);
    const [sucursales, SetSucursales] = useState([]);
    const [commands, setCommands] = useState([]);
    const [clients, setClients] = useState([]);
    const [historyComm, setHistoryComm] = useState([]);
    const [commandsDataModal, setCommandsDataModal] = useState({});
    // const [listWareHouseKits, setListWareHouseKits] = useState([]);

    /* modals */
    const [showCommandsDataModal, setShowCommandsDataModal] = useState(false);

    /* Estados */
    const [isNewCommand, setIsNewCommand] = useState(true);
    const [showListsComponents, setShowListsComponents] = useState({ clients: false, kits: false });

    /* selected */
    const [org, setOrg] = useState('');
    const [suc, setSuc] = useState('');
    const [sucId, setSucId] = useState('');
    const [command, setCommand] = useState('');
    const [clte, setClte] = useState('');
    const [kit, setKit] = useState('');
    //const [customerTypes, setcustomerTypes] = useState('');

    const customerTypes = { '4d8e7b38-de1d-47cf-9731-50fb249e0d87': 'CC', 'c32b3f5e-5de3-4954-96e2-a87a29d89fcd': 'CL', 'b952d4e7-a30a-4c02-84c8-ed2d91ca60e8': 'CJ', '839c5a09-1a3f-4794-b3b1-3296b8ac8e42': 'CM', '21ebd2b0-f840-4ad4-aa74-1d76f46fd54c': 'CB001' }
    const [customerTypeName, setCustomerTypeName] = useState('');

    const [dataCommandSend, setDataCommandSend] = useState({ complete: true });

    // const [dataKitSend, setDataKitSend] = useState({ complete: true });

    /* tbl LookUps */
    const [lookUpCommands, setLookUpCommands] = useState({});
    const [lookUpWareHouses, setLookUpWareHouses] = useState({});

    /* tabla */
    const columnas = [
        {
            title: 'Comando',
            field: 'remoteCommand.id',
            lookup: lookUpCommands
        },
        {
            title: 'sucursales',
            field: 'warehouseId',
            lookup: lookUpWareHouses
        },
        {
            title: 'Enviado',
            render: (o, i) => o.createdAt && moment(o.createdAt, 'YYYY-MM-DDTHH:mm:ss').add(-5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Actualizado',
            render: (o, i) => o.updatedAt && moment(o.updatedAt, 'YYYY-MM-DDTHH:mm:ss').add(-5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'estatus',
            render: (obj, name) => {
                let re = ''
                obj.remoteControlStatus.forEach((el, eId) => {
                    re += `${moment.unix(el.startDatetime).format('YYYY-MM-DD HH:mm:ss')} [${el.remoteControlStatusType}] \n`
                });
                return re
            }
        }
    ]

    const location = {
        header: {
            actions: props.translate('Actions')
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} ${props.translate('of')} {count}`,
            labelRowsSelect: props.translate('Rows'),
            labelRowsPerPage: props.translate('Rows per page'),
            firstAriaLabel: props.translate('First Page'),
            firstTooltip: props.translate('First Page'),
            previousAriaLabel: props.translate('Previous Page'),
            previousTooltip: props.translate('Previous Page'),
            nextAriaLabel: props.translate('Next Page'),
            nextTooltip: props.translate('Next Page'),
            lastAriaLabel: props.translate('Last Page'),
            lastTooltip: props.translate('Last Page')
        }
    }

    useEffect(() => {
        if (firstrender.current) {
            firstrender.current = false;
            getLookUpWareHouses();
            getComandos();
            getCommandHist();
            getEmpresas();
            getCustomerTypes()
            return
        }
        const cleanup = () => {
            // listCommSubscribe.unsubscribe();
        }
        return cleanup();
    }, [clients]);

    useEffect(() => {
        getPartialClients(sucId, listCustomersAudit, {}, []).then(res => { }).catch(err => { }).finally(() => setCtesLoader(false));
    }, [commandsDataModal, sucId]);

    useEffect(() => {
    }, [ctesLoader]);

    const handleSelectOrganization = (id, name) => {
        getSucursales(id)
        setOrg(name)
    }

    const handleSelecSucursal = (el, obj, nombre) => {
        setSuc(`${obj.warehouseId} - ${obj.warehouseName}`);
        setSucId(obj.id);
        setClients([]);
    }

    const handleSelecCommand = comando => {
        console.log(comando)
        switch (comando.id) {
            case '7cd179ce-562b-4dec-a3ea-110a7d2fd7bf':
                /* mostrar lista de clientes */
                setProcessComplete(false);
                setShowListsComponents({ kits: false, clients: true });
                break;
            case '1998e0a0-1cfc-41c1-b897-d0df617fa02b':
                /* mostrar lista de kits */
                setProcessComplete(false);
                setShowListsComponents({ kits: true, clients: false })
                break
            default:
                setShowListsComponents({ kits: false, clients: false })
                setProcessComplete(true);
                break;
        }
        setCommand(comando.remoteCommandName);
        setCommandsDataModal(comando)
        setIsNewCommand(false);
        setCtesLoader(true);
    }

    const handleSelectClient = async ({ target }, newVal) => {
        newVal && console.log('newVal: ', newVal.customerType.id, newVal.customerType.customerTypeName, newVal);
        !newVal && setCustomerTypeName('');
        newVal && setCustomerTypeName(customerTypes[newVal.customerType.id])
        newVal && setClte(newVal.id);
        newVal && setProcessComplete(true);
    }

    const handleSelectKit = (newKit) => {
        console.log('handleSelectKit: ', newKit);
        setKit(newKit);
        setProcessComplete(true);
    }

    const handleSendCommand = async () => {
        setAddCommLoader(true);
        let comando = JSON.parse(commandsDataModal.command)
        // console.log(comando, kit)
        if (comando.data && comando.data.customerId != undefined) {
            comando.data.customerId = clte
        }
        if (comando.oper == 'addKit') {
            comando.data = kit
        }
        // if ( comando.oper == "updateUserMTCenter") {
        //      comando.data = {
        //         listserviceName: ['MTCenter-TAE-TCO'],
        //         password: 38247,
        //         property:"commercial_chain"
        //     }
        // }
        let newComm = {
            input: {
                remoteControlRemoteCommandId: commandsDataModal.id,
                warehouseId: sucId,
                params: JSON.stringify(comando),
                remoteControlStatus: { startDatetime: parseInt(new Date().getTime() / 1000), remoteControlStatusType: 'send' },
                // warehouseName: `${warehouseId} - ${warehouseName}`,
                // commandName: commandsDataModal.remoteCommandName
            }
        }
        console.log(newComm)
        await api(createRemoteControl, newComm).then(nComSaved => {
            console.log('guardado!...', nComSaved);
            props.showMessage(`El comando ha sido enviado...`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            resetForm();
            getCommandHist();
        }).catch(err => {
            console.log('err al guardar comando: ', err)
            props.showMessage(`Comando no enviado!!!...`, SNACKBAR_ERROR, 10000, null, null, null, null, null, null, props);
        }).finally(() => setAddCommLoader(false))
        // console.log('Comando a enviar: ',newComm);
        setAddCommLoader(false)
    }

    const handleToggleCommandsDataModal = () => {
        setShowCommandsDataModal(!showCommandsDataModal);
    }

    const handleSaveCommandsDataModal = async commData => {
        // console.log('datos del modal: ', commData);
        if (isNewCommand) {
            await api(createRemoteCommand, { input: commData }).then(res => {
                // console.log('Comando Guardado: ', res);
                props.showMessage(`Guardado!`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            }).catch(err => {
                console.log('comando no guardado: ', err);
                props.showMessage(`Error al intentar agregar el registro...`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            })
        } else {
            commData.id = commandsDataModal.id;
            commData._version = commandsDataModal._version;
            await api(updateRemoteCommand, { input: commData }).then(res => {
                // console.log('comando actualizado: ', res);
                props.showMessage(`Actualizado!`, SNACKBAR_SUCCESS, null, null, null, null, null, null, null, props);
            }).catch(err => {
                console.log('comando no actualizado: ', err)
                props.showMessage(`Error al intentar actualizar el registro...`, SNACKBAR_ERROR, null, null, null, null, null, null, null, props);
            })
        }
        await getComandos();
        handleToggleCommandsDataModal();
    }

    const resetForm = () => {
        setDataCommandSend({ complete: false });
    }

    const getEmpresas = async () => {
        setOrgLoader(true);
        await api(listOrganizations, { limit: 300 }).then(org => {
            setEmpresas(org)
        }).catch(err => {
            console.log('load org. err: ', err)
        }).finally(() => setOrgLoader(false))
    }

    const getSucursales = async empId => {
        let sucursales = [];
        setSucLoader(true);
        await api(listWarehouses, { limit: 300 }).then(suc => {
            console.log(suc)
            console.log(empId)
            if (suc.length != undefined && suc.length != 0) {
                sucursales = suc.filter(sucursal => sucursal.organization.id == empId)
                console.log(sucursales);
                SetSucursales(sucursales);
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => setSucLoader(false))
    }

    const getCustomerTypes = async () => {
        let customerTypes = [];
        await api(listCustomerTypes, { limit: 300 }).then(types => {
            types && types.forEach(_e => {
                customerTypes.push({ [_e.id]: _e.customerTypeId })
            });
            console.log("types", ...customerTypes)
            //setcustomerTypes(...customerTypes)
        }).catch(err => {
            console.log(err)
        }).finally(() => console.log(""))
    }

    const getLookUpWareHouses = async () => {
        let lookUpWareHousesObj = {}
        await api(listWarehouses, { limit: 300 }).then(wh => {
            wh && wh.forEach(el => {
                lookUpWareHousesObj[el.id] = el.warehouseName
            })
            setLookUpWareHouses(lookUpWareHousesObj);
        })
    }

    const getComandos = async () => {
        let lookupCommandsObj = {}
        await api(listRemoteCommands, { limit: 300 }).then(commands => {
            commands.forEach(cm => {
                lookupCommandsObj[cm.id] = cm.remoteCommandName
            })
            setLookUpCommands(lookupCommandsObj);
            setCommands(commands);
        }).catch(err => {
            console.log('err: ', err)
        })
    }

    // const listCommSubscribe = API.graphql(graphqlOperation(onCreateRemoteControl)).subscribe({
    //     next: ({cmd, value}) => {
    //         console.log('subscription event: ', cmd, value)
    //         getCommandHist();
    //     },
    //     error: error => console.warn('subscription err: ', error)
    // })

    const getCommandHist = async () => {
        setTblLoaderData(true)
        setHistoryComm([]);
        await api(listRemoteControls, { limit: 300 }).then(historic => {
            if (historic.length > 0) {
                // console.log('comandos enviados...', historic)
                setHistoryComm(historic);
            }
        }).catch(err => {
            console.log('historyCatch: ', err)
        }).finally(() => setTblLoaderData(false));
    }

    const getPartialClients = async (sudursalId, query, params, response) => {
        setCtesLoader(true);
        if (commandsDataModal.id == '7cd179ce-562b-4dec-a3ea-110a7d2fd7bf') {
            if (response.length == 0) {
                props.showMessage(`Actualizando lista de Clientes... por favor espere...`, SNACKBAR_WARNING, null, null, null, null, null, null, null, props);
            } else {
                props.showMessage(`Actualizando lista de Clientes: ${response.length}`, SNACKBAR_INFO, 1000, null, null, null, null, null, null, props);
            }
            await apiPartial(query, params)
                .then(res => {
                    res.items && res.items.forEach(cte => {
                        if (cte.customerType != null && cte.customerId != null && cte.customerId != 'blacklist') {

                            // PREGUNTAR POR QUÉ EXISTE ESTE FILTRO
                            // if (cte.customerType.id != '4d8e7b38-de1d-47cf-9731-50fb249e0d87') {
                            // console.log('buscando tipo de cliente: ', cte.customerType.id, cte.customerType.customerTypeName );
                            response.push(cte);
                            // } else {
                            api(getWarehouseCustomer, { customerId: cte.id, warehouseId: sudursalId }).then(wareh => {
                                if (wareh != null) {
                                    // console.log('cte. Conviene: ', cte );
                                    response.push(cte);
                                }
                            }).catch(err => {
                                console.log('getWareH err.: ', err);
                            });
                            // }
                        }
                    })
                    // console.log('response->', response);

                    // BUSCAMOS POR ID DEL CLIENTE (SUSTITUIR EL ID POR EL ID DEL CLIENTE BUSCADO)
                    const customerFound = response.find(customer => {
                        if (customer.id == "84f11519-936b-479d-970e-389c2adfac65") {
                            return customer
                        }
                    })

                    // BUSCAMOS POR EL NOMBRE DEL TYPENAME
                    // const customerFound = response.filter(customer => {
                    //     if(customer.customerType.customerTypeName == "Clientes Conviene Mayoristas") {
                    //         return customer
                    //     }
                    // })

                    console.log('objeto encontrado', customerFound);


                    const newCustomerList = response.filter(customer => {
                        // if (!customer.customerType.customerTypeId && customer.customerType.customerTypeName in listCustomersTypes) {
                        if (customer.customerType.customerTypeName in listCustomersTypes) {

                            return {
                                ...customer,
                                customerType: listCustomersTypes[customer.customerType.customerTypeName]
                            }
                        }

                        // console.log({
                        //     ...customer,
                        //     customerType: listCustomersTypes[customer.customerType.customerTypeName]
                        // })
                        // return customer
                    })

                    // console.log('newCustomerList->', newCustomerList);
                    setClients(newCustomerList);

                    // setClients(response);

                    if (res.nextToken) {
                        return getPartialClients(sudursalId, query, { ...params, nextToken: res.nextToken }, response);
                    } else {
                        props.showMessage('Lista de Clientes completada...', SNACKBAR_SUCCESS, 15000, null, null, null, null, null, null, props);
                    }
                }).catch(err => {
                    console.log('error en getPartCtes: ', err);
                });
            return response
        }
    }

    return (
        <>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='row' m={1} minWidth={'99%'} >
                <Container>
                    <Grid container spacing={3} >
                        <Grid item xs={12} >
                            <Paper elevation={2} >
                                <Typography variant='h5' >Enviar Comando</Typography>
                                <Grid item>
                                    <FormControl component='fieldset' className={classes.formControlHalf} >
                                        <InputLabel id='labelEmpresas' >Seleccione Empresa...</InputLabel>
                                        <Select labelId='labelEmpresas' id='empresa' value={org} >
                                            {orgLoader ? <LinearProgress /> : null}
                                            {empresas && empresas.map((emp, key) => <MenuItem key={key} value={emp.organizationName} onClick={() => handleSelectOrganization(emp.id, emp.organizationName)} >{emp.organizationName}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <Autocomplete id='sucursales' options={sucursales} getOptionLabel={opt => `${opt.warehouseId} - ${opt.warehouseName}`} openOnFocus renderInput={prms => sucLoader && sucursales.length == 0 ? <LinearProgress /> : <TextField {...prms} label='Seleccione Sucursal...' variant='standard' />} disabled={org == ''} style={{ display: 'inline-block', width: '45%', marginTop: '1rem' }} onChange={handleSelecSucursal} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component='fieldset' className={classes.formControl3} disabled={suc == ''} >
                                        <InputLabel id='labelCommand' >Acciones...</InputLabel>
                                        <Select labelId='labelCommand' id='command' value={command} >
                                            {commands.map((comm, key) => <MenuItem key={key} value={comm.remoteCommandName} onClick={() => handleSelecCommand(comm)} >{comm.remoteCommandName}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <Tooltip title='agregar/Editar comando' arrow >
                                        <IconButton disabled={suc == ''} color='primary' component='span' aria-label='agregar/editar' onClick={handleToggleCommandsDataModal} >
                                            <AddCircle />
                                        </IconButton>
                                    </Tooltip>

                                    {/* El siguiente componente debería cambiar según el comando elegido y sus necesidades */}
                                    {showListsComponents.clients && <Autocomplete id='Clients' options={clients} getOptionLabel={option => customerTypes[option.customerType.id] + ' ' + option.customerName} openOnFocus renderInput={params => ctesLoader && clients == 0 ? <LinearProgress /> : <TextField {...params} label='Lista de clientes... Seleccione...' variant='standard' margin='none' /* InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position='start' >{ customerTypeName }</InputAdornment> ) }} */ />} disabled={command == ''} style={{ display: 'inline-block', width: '40%', marginTop: '.5rem' }} onChange={handleSelectClient} />}

                                    {showListsComponents.kits && <ShowListKits warehouseId={sucId} handlerChanges={handleSelectKit} showMessage={props.showMessage} btnState={setProcessComplete} />}

                                    <Button disabled={!processComplete} size='small' color='primary' variant='contained' endIcon={<Send />} style={{ marginLeft: '.5rem', marginTop: '.5rem' }} onClick={handleSendCommand} >enviar</Button>
                                </Grid>
                                <Grid item xs={12} >
                                    <Divider variant='fullWidth' />
                                </Grid>
                                <Grid item xs={12} >
                                    <MaterialTable title='Historial de comandos' columns={columnas} data={historyComm} isLoading={tblLoaderData} options={{ padding: 'dense' }} localization={location} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <AddCommand open={showCommandsDataModal} onClose={handleToggleCommandsDataModal} onSave={handleSaveCommandsDataModal} data={commandsDataModal} translate={props.translate} />
        </>
    )
}

Commands.propTypes = {

}

export default withSnackbar(Commands)

